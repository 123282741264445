<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" class="py-5" v-if="fetchingDataHarvest">
          <div class="text-center text-muted">
            <b-spinner></b-spinner>
            <br>
            Données de la récolte...
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-row>
            <b-col cols="3">
              <b-card class="production-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown text-white">
                  <template slot="button-content" variant="primary">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item :to="harvestURL" active-class="">Ouvrir la récolte</b-dropdown-item>
                  <b-dropdown-item :to="harvestList" active-class="">Toutes les récoltes</b-dropdown-item>
                </b-dropdown>
                <h4 class="after-production">
                  {{(currentHarvest.usedHarvestDuration
                  /currentHarvest.totalHarvestDuration).toFixed(2)*100}}
                  %
                </h4>
                <p class="text-stat">{{currentHarvest.name}}</p>
              </b-card>
            </b-col>
            <b-col cols="3">
              <b-card class="person-number-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown text-white">
                  <template slot="button-content" variant="primary">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item>Utilisateurs({{personWorking.filter(item => item.user).length}})</b-dropdown-item>
                  <b-dropdown-item>Employés({{personWorking.filter(item => !item.user).length}})</b-dropdown-item>
                </b-dropdown>
                <h4 class="mb-0">{{personWorking.length}}</h4>
                <p class="text-stat"
                   title="">
                  personnes travaillent sur cette récolte
                </p>
              </b-card>
            </b-col>
            <b-col cols="3" v-if="currentCultures.length !=0">
              <b-card class="current-step-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown" v-if="currentCultures.length >=2">
                  <template slot="button-content" variant="primary" class="information-dropdown">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item v-for="culture in currentCultures.filter(item => item.id != currentCultureId)"
                                   @click="changeCurrentStep(culture.id)">
                    {{culture.name}}
                  </b-dropdown-item>
                </b-dropdown>
                <h4 class="mb-0">
                  {{ ((currentCultureInfo.durationUsed / currentCultureInfo.totalCultureDuration)*100).toFixed(2)}}
                  %
                  <span style="font-size: 12px">(récolte en cours)</span>
                </h4>
                <p class="text-stat">{{currentCultureInfo.name}}</p>
              </b-card>
            </b-col>
            <b-col cols="3">
              <b-card class="spent-money-info text-white padding-conform">
                <b-dropdown right class="float-right information-dropdown pr-0">
                  <template slot="button-content" variant="primary" class="information-dropdown">
                    <i class="fa fa-th-list"></i>
                  </template>
                  <b-dropdown-item>Compte Courant</b-dropdown-item>
                  <b-dropdown-item>Bilan</b-dropdown-item>
                </b-dropdown>
                <h4 class="mb-0">{{currentSpentAmount + exploitationCurrency}}</h4>
                <p class="text-stat">dépenses effectuées</p>
              </b-card>
            </b-col>
          </b-row>
          <b-card header-tag="header" header-bg-variant="transparent">
            <b-tabs class="dashboard-border">
              <b-tab>
                <template slot="title">Autres informations</template>
                <b-row class="mb-5" v-if="currentCultures.length !=0">
                  <b-col cols="12" class="title-other-informations mb-2 mt-0">
                    <i class="fa fa-gavel"></i>
                    <label>Activités en cours</label>
                  </b-col>
                  <template v-if="currentActivitiesList.length == 0">
                    Vous n'avez aucune activité en cours
                  </template>
                  <template v-else>
                    <b-col cols="6" class="activity-informations mt-3"
                           v-for="activity in currentActivitiesList.find(item=>item.cultureId == currentCultureId).activities">
                      <div class="d-flex justify-content-between">
                        <h6>
                          {{activity.name}}
                        </h6>
                        <h6>{{activity.duration}} jours</h6>
                      </div>
                      <b-progress :max="activity.duration">
                        <b-progress-bar :value="activity.durationUsed"
                                        :label="`${((activity.durationUsed/activity.duration)*100).toFixed(2)}%`"></b-progress-bar>
                      </b-progress>
                    </b-col>
                  </template>
                </b-row>
                <b-row class="mb-5">
                  <b-col cols="12" class="title-other-informations mb-2 mt-0">
                    <i class="fa fa-envira"></i>
                    <label>Intrants</label>
                  </b-col>
                  <template v-if="inputsList.length ==0">
                    Vous n'avez utilisé aucun intrant pour cette production
                  </template>
                  <template v-else>
                    <b-col cols="6" class="activity-informations mt-3" v-for="input in inputsList">
                      <div class="d-flex justify-content-between">
                        <h6>
                          {{input.name}}
                        </h6>
                        <h6>{{input.totalQuantity}} {{input.unit}}</h6>
                      </div>
                      <b-progress :max="input.totalQuantity">
                        <b-progress-bar :value="input.quantityUsed"
                                        :label="`${((input.quantityUsed/input.totalQuantity)*100).toFixed(2)}%`"></b-progress-bar>
                      </b-progress>
                    </b-col>
                  </template>
                </b-row>
                <b-row>
                  <b-col cols="12" class="title-other-informations mb-2 mt-0">
                    <i class="fa fa-envira"></i>
                    <label>Equipements</label>
                  </b-col>
                  <template v-if="equipmentsList.length ==0">
                    Vous n'avez utilisé aucun equipement pour cette production
                  </template>
                  <template v-else>
                    <b-col cols="6" class="activity-informations mt-3" v-for="equipment in equipmentsList">
                      <div class="d-flex justify-content-between">
                        <h6>
                          {{equipment.name}}
                        </h6>
                        <h6>{{equipment.totalQuantity}}</h6>
                      </div>
                      <b-progress :max="equipment.totalQuantity">
                        <b-progress-bar :value="equipment.quantityUsed"
                                        :label="`${((equipment.quantityUsed/equipment.totalQuantity)*100).toFixed(2)}%`"></b-progress-bar>
                      </b-progress>
                    </b-col>
                  </template>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title">Dépenses ({{spentSalesData.length}})</template>
                <b-modal title="Enregistrer une dépense" class="modal-primary" v-model="addModalSpent"
                         @hide="onAddModalSpentClose" size="lg">
                  <b-form-row>
                    <b-col class="mr-4">
                      <c-input container-class="mb-3" label="Libellé de la dépense" placeholder="Ex: Dépense 1"
                               v-model="spentName" :state="spentNameState">
                        Veuillez saisir un nom
                      </c-input>
                      <c-input container-class="mb-3" type="quantity" label="Coût de la dépense" placeholder="Ex: 50000"
                               v-model="spentAmount" :unit="exploitationCurrency"
                               step="500" :state="spentAmountState">
                        Veuillez spécifier un coût
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        
                        <c-input container-class="mb-3" type="select" label="Catégorie de la dépense"
                                 v-model="spentCategory"
                                 :options="listSpentCategories" :state="spentCategoryState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez la catégorie de la dépense --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button"
                                  title="Ajouter une nouvelle catégorie"
                                  @click="addSpentCategory" :disabled="spentCategoryNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer une nouvelle catégorie emetteur"
                               v-model="spentCategoryNew" :state="spentCategoryState" v-if="spentCategoryNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      
                      <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement" v-model="spentDate"
                               :time.sync="spentTime">
                        Veuillez entrer une durée
                      </c-input>
                    </b-col>
                    <b-col>
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Emetteur de la dépense"
                                 v-model="spentEmitter"
                                 :options="spentsMembersList" :state="spentEmitterState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez l'emetteur --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addSpentEmitter" :disabled="spentEmitterNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouvel emetteur"
                               v-model="spentEmitterNew" :state="spentEmitterState" v-if="spentEmitterNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Bénéficiaire de la dépense"
                                 v-model="spentReceiver"
                                 :options="spentsMembersList" :state="spentReceiverState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez le bénéficiaire --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addReceiveEmitter" :disabled="spentReceiverNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouveau bénéficiaire"
                               v-model="spentReceiverNew" :state="spentReceiverState" v-if="spentReceiverNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="textarea" label="Description de la dépense"
                               placeholder="Entrez une description..." v-model="spentDescription">
                      </c-input>
                    </b-col>
                    <b-form-file
                      v-model="spentFiles"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                    ></b-form-file>
                  </b-form-row>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAddSpent"
                                    :fetching=fetchingCreateSpent>
                      Enregistrer
                    </button-spinner>
                    <b-button variant="secondary" @click="addModalSpent = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                <c-table :table-data="spentSalesData" :fields="spentFields" :actions="spentActions"
                         :per-page=5
                         striped outlined>
                  <template slot="empty">
                    <p class="text-center">Aucune dépense enregistrée.</p>
                  </template>
                </c-table>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, price, Toast, moneyFormat, Regex} from "../../../helpers";
  
  export default {
    name: "ExploitationDashboardHarvest",
    title: "PIA - Tableau de Bord",
    data() {
      return {
        fetchingDataHarvest: false,
        harvest: {},
        currentCultureId: 0,
        currentCultures: [],
        currentCultureInfo: [],
        currentActivitiesList: [],
        personWorking: [],
        inputsList: [],
        equipmentsList: [],
        currentHarvest: {},
        currentSpentAmount: 0,
        
        spentFields: [
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountCurrency', label: 'Montant'},
          {key: 'spentEmitter.name', label: 'Effectuée par'},
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'actions', label: 'Actions'},
        ],
        spentActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.spentDetails},
        ],
        
        
        spentName: '',
        spentAmount: '',
        
        spentEditId: 0,
        spentCategory: null,
        spentCategoryNew: "",
        spentCategoryNewDisplay: false,
        
        spentDate: "",
        spentTime: "",
        spentEmitter: null,
        spentReceiver: null,
        spentEmitterNew: "",
        spentReceiverNew: "",
        spentDescription: '',
        fetchingCreateSpent: false,
        submittedSpent: false,
        spentFiles: [],
        addModalSpent: false,
        editModalSpent: false,
        
        spentsMembers: [],
        spentEmitterNewDisplay: false,
        spentReceiverNewDisplay: false,
        
        spentCategories: [],
      }
    },
    created() {
      this.fetchingDataHarvest = true
      Api.get('/exploitation/production/harvest', {
        exploitationId: this.exploitationId,
        harvestId: this.harvestId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.harvest = res.data.data
            this.allInformationsProduction()
            this.currentStepInformations()
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingDataHarvest = false
        })
      
      
      Api.get('/exploitation/member/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentsMembers = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/spent-category/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentCategories = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
    },
    mounted() {},
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      harvestId() {
        return this.$route.params.harvestId
      },
      harvestURL() {
        return '/exploitation/' + this.exploitationId + '/production/harvest/' + this.harvestId
      },
      harvestList() {
        return '/exploitation/' + this.exploitationId + '/production/harvests/list'
      },
      exploitationCurrency() {
        return price()
      },
      spentSalesData() {
        return this.harvest.spents.map(spentSale => ({
          ...spentSale,
          amountCurrency: spentSale.amount + ' ' + this.exploitationCurrency,
          formattedDate: spentSale.spentDate.split(' ').join(' à ')
        }))
      },
      spentNameState() {
        return !this.submittedSpent || this.spentName.trim().length >= 3 ? null : false
      },
      spentAmountState() {
        return !this.submittedSpent || Regex.isPositiveNumber(this.spentAmount) ? null : false
      },
      spentCategoryState() {
        return !this.submittedSpent || (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3) ? null : false
      },
      spentEmitterState() {
        return !this.submittedSpent || (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3) ? null : false
      },
      spentReceiverState() {
        return !this.submittedSpent || (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3) ? null : false
      },
      listSpentCategories() {
        return this.spentCategories.map(cat => ({
          text: cat.name,
          value: cat
        }))
      },
      spentsMembersList() {
        return this.spentsMembers.map(member => ({
          text: member.name,
          value: member
        }))
      }
    },
    watch: {
      error(e) {
        if (e) {
          Toast.error(e)
        }
      }
    },
    methods: {
      allInformationsProduction() {
        let personWorkingList = []
        let inputs = []
        let equipments = []
        let currentActivities = []
        let currentActivitiesList = []
        let currentCultures = []
        let totalHarvestDuration = 0
        let usedHarvestDuration = 0
        this.harvest.cultures.map(cult => {
          currentActivities = []
          cult.activities.map(act => {
            // the total duration of the duration
            totalHarvestDuration += act.duration
            personWorkingList = personWorkingList.concat(act.staff.map(staf => ({
              id: staf.id,
              user: staf.user
            })))
            
            inputs = inputs.concat(act.inputs.map(inp => ({
              id: inp.id,
              quantity: inp.quantity,
              unit: inp.unit,
              name: inp.name,
              status: act.status.name
            })))
            equipments = equipments.concat(act.equipments.map(equi => ({
              id: equi.id,
              quantity: equi.quantity,
              name: equi.name,
              status: act.status.name
            })))
            if (act.status.name == 'RUNNING') {
              // a part of the used activities
              const delta = ((new Date()).getTime() - (new Date(act.startDate)).getTime()) / (1 * 24 * 60 * 60 * 1000)
              usedHarvestDuration += delta
              if (delta > act.duration)
                usedHarvestDuration += act.duration
              else usedHarvestDuration += delta
              // handle the state of activities
              currentActivities.push({
                id: act.id,
                name: act.name,
                duration: act.duration,
                // this difference is in milliseconds -> days
                durationUsed: delta > act.duration ? act.duration : delta
              })
            }
            else if (act.status.name == 'TERMINATED')
            // a part of the used activities
              usedHarvestDuration += act.duration
            
          })
          
          // handle the current steps list
          if (cult.status.name == 'RUNNING') {
            currentActivitiesList.push({
              cultureId: cult.id,
              activities: currentActivities
            })
            console.log(currentActivitiesList)
            let totalCultureDuration = 0
            const durationUsed = cult.activities.reduce((acc, val) => {
              totalCultureDuration += val.duration
              if (val.status.name == 'RUNNING') {
                const actState = ((new Date()).getTime() - (new Date(val.startDate)).getTime()) / (1 * 24 * 60 * 60 * 1000)
                if (actState > val.duration) return acc + val.duration
                console.log('une activite qui a dure ', actState)
                return acc + actState
              } else return acc
            }, 0)
            currentCultures.push({
              id: cult.id,
              name: cult.name,
              totalCultureDuration,
              durationUsed
            })
          }
        })
        
        // we remove duplicates values
        let result = []
        personWorkingList.map(person => {
          if (!result.find(item => item.id == person.id && item.user == person.user))
            result.push(person)
        })
        personWorkingList = result
        // handle the list of inputs
        //begin by removing duplicates value
        let inputsList = Array.from(new Set(inputs.map(el => el.id)))
        //next set the quantity used and the total
        inputsList = inputsList.map(inputItem => {
          let totalQuantity = 0
          const quantityUsed = inputs.reduce((acc, val) => {
            if (val.id == inputItem.toString()) {
              totalQuantity += val.quantity
              if (val.status == 'RUNNING' || val.status == 'TERMINATED')
                return acc + val.quantity
              else return acc
            }
            return acc
          }, 0)
          
          return {
            ...inputs.find(item => item.id == inputItem.toString()),
            quantityUsed,
            totalQuantity
          }
        })
        //handle the list of equipments
        //begin by removing duplicates value
        let equipmentsList = Array.from(new Set(equipments.map(el => el.id)))
        //next set the quantity used and the total
        equipmentsList = equipmentsList.map(equipmentItem => {
          let totalQuantity = 0
          let quantityUsed = equipments.reduce((acc, val) => {
            if (val.id == equipmentItem.toString()) {
              totalQuantity += val.quantity
              if (val.status == 'RUNNING' || val.status == 'TERMINATED') {
                console.log('enter here')
                return acc + val.quantity
              } else
                return acc
            }
            return acc
          }, 0)
          
          return {
            ...equipments.find(item => item.id == equipmentItem.toString()),
            quantityUsed,
            totalQuantity
          }
        })
        
        
        this.currentCultures = currentCultures
        this.currentCultureId = currentCultures[0] ? currentCultures[0].id : null
        this.currentActivitiesList = currentActivitiesList
        this.personWorking = personWorkingList
        this.inputsList = inputsList
        this.equipmentsList = equipmentsList
        this.currentHarvest = {
          id: this.harvest.id,
          name: this.harvest.name,
          usedHarvestDuration, totalHarvestDuration
        }
        let currentSpentAmount = this.harvest.spents.reduce((acc, val) => {
          if (val.amount) return acc + val.amount
          else return acc
        }, 0)
        if (currentSpentAmount.toString().split('.').length >= 2) {
          this.currentSpentAmount = moneyFormat(currentSpentAmount, 2, 3, ' ', '.')
        } else {
          this.currentSpentAmount = moneyFormat(currentSpentAmount, 0, 3, ' ')
        }
      },
      currentStepInformations() {
        if (this.currentCultures.length == 0)
          return
        this.currentCultureInfo = this.currentCultures.find(item => item.id == this.currentCultureId)
      },
      changeCurrentStep(cultharvestID) {
        this.currentCultureId = cultharvestID
        this.currentStepInformations()
      },
      
      onAddModalSpentClose() {
        this.editModalSpent = false
        
        this.spentName = ''
        this.spentAmount = ''
        this.spentDescription = ''
        this.submittedSpent = false
        this.spentDate = ""
        this.spentTime = ""
        this.spentReceiver = null
        this.spentReceiverNew = ""
        this.spentReceiverNewDisplay = false
        this.spentEmitter = null
        this.spentEmitterNew = ""
        this.spentCategoryNew = ""
        this.spentCategoryNewDisplay = false
      },
      validSpent() {
        return this.spentName.trim().length >= 3 &&
          Regex.isPositiveNumber(this.spentAmount)
          && (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3)
          && (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3)
          && (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3)
      },
      getFiles(files) {
        return Promise.all(files.map(file => {
          var reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = (ev => {
              resolve(ev.target.result)
            })
            reader.readAsDataURL(file)
          })
        }))
      },
      async onSubmitAddSpent() {
        this.submittedSpent = true
        if (!this.validSpent())
          return
        
        this.fetchingCreateSpent = true
        
        const base64Files = await this.getFiles(this.spentFiles)
        
        // recuperation de l'emetteur
        const emitter = {
          isExist: this.spentEmitter ? true : false,
          user: this.spentEmitter || this.spentEmitterNew
        }
        // recuperation du beneficiaire
        const receiver = {
          isExist: this.spentReceiver ? true : false,
          user: this.spentReceiver || this.spentReceiverNew
        }
        
        
        // retrieving the old|new mode
        const cat = {
          isExist: this.spentCategory ? true : false,
          category: this.spentCategory || this.spentCategoryNew
        }
        
        
        Api.post('/exploitation/production/harvest/spent/update', {
          exploitationId: this.exploitationId,
          harvestId: this.harvestId,
          spentId: this.spentEditId,
          name: this.spentName,
          amount: this.spentAmount,
          spentEmitter: emitter,
          spentReceiver: receiver,
          spentCategory: cat,
          spentDate: this.spentDate + " " + this.spentTime,
          description: this.spentDescription,
          attachments: base64Files,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              const newSpents = this.harvest.spents.filter(sp => sp.id != res.data.data.id)
              newSpents.push(res.data.data)
              this.harvest.spents = newSpents
              this.addModalSpent = false
              Toast.success('Dépense enregistrée avec succès')
              this.refreshCategoryList()
              this.refreshSpentCategoryList()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateSpent = false
          })
      },
      addSpentCategory() {
        this.spentCategoryNewDisplay = true
      },
      addSpentEmitter() {
        this.spentEmitterNewDisplay = true
      },
      addReceiveEmitter() {
        this.spentReceiverNewDisplay = true
      },
      refreshCategoryList() {
        
        Api.get('/exploitation/member/all', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentsMembers = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      refreshSpentCategoryList() {
        
        Api.get('/exploitation/spent-category/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentCategories = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      spentDetails(spent) {
        
        this.addModalSpent = true
        this.editModalSpent = true
        
        
        this.spentEditId = spent.id
        this.spentName = spent.name
        this.spentAmount = spent.amount
        this.spentDescription = spent.description
        this.spentDate = spent.spentDate.split(' ')[0].split('/').reverse().join('-')
        this.spentTime = spent.spentDate.split(' ')[1]
        this.spentEmitter = this.spentsMembers.find(m => m.id == spent.spentEmitter.id
          && m.user == spent.spentEmitter.user)
        this.spentReceiver = this.spentsMembers.find(m => m.id == spent.spentReceiver.id
          && m.user == spent.spentReceiver.user)
        this.spentCategory = this.spentCategories.find(entry => entry.id == spent.spentCategory.id)
      }
    }
  }
</script>

<style>
  .information-dropdown .btn.btn-secondary{
    background-color: transparent !important;
    border-color: transparent !important;
    color: white !important;
  }

</style>
<style scoped>
  .production-info{
    background: linear-gradient(45deg, #321fdb, #1f1498);
  }
  
  .person-number-info{
    background: linear-gradient(45deg, #39f, #2982cc);
  }
  
  .current-step-info{
    background: linear-gradient(45deg, #f9b115, #f6960b);
  }
  
  .spent-money-info{
    background: linear-gradient(45deg, #e55353, #d93737);
  }
  
  .text-stat{
    height: 3em;
    text-overflow: ellipsis;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .after-production::after{
    font-size: 14px;
    content: " \2022 Pour moi";
    font-family: "Segoe UI Historic";
  }
  
  .title-other-informations{
    color: white;
    font-weight: bold;
    font-size: 15px;
    padding-top: 5px;
    margin-bottom: 25px;
    border-radius: 0 10px 10px 0;
    background: linear-gradient(45deg, #767676, #c1c1c1);
  }
  
  .title-other-informations i{
    margin-right: 5px;
  }
  
  .title-other-informations label{
    text-decoration: underline;
  }
  
  .title-other-informations::after{
    content: " \2022";
  }
  
  .activity-informations > div:first-child > h6:first-child{
    width: calc(100% - 150px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .padding-conform .card-body{
    padding: 1rem .3rem 1rem 1rem !important;
  }
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }
</style>
